<template><div><h1 id="installing-windows" tabindex="-1"><a class="header-anchor" href="#installing-windows"><span>Installing Windows</span></a></h1>
<div class="custom-container warning"><p class="custom-container-title">WARNING</p>
<p>Do not use Windows 8 or older. Only recent Windows 10 and 11 builds are supported.</p>
</div>
<div class="custom-container danger"><p class="custom-container-title">DANGER</p>
<p>Do not use unofficial versions of Windows, such as Ghost Specter or tiny10/11. <strong>They are not supported</strong> and may contain <strong>undetectable malware</strong> and/or security vulnerabilities.</p>
</div>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>If you want to create a Windows installer from ChromeOS, see the <RouteLink to="/docs/firmware/ventoy.html">Installing Ventoy</RouteLink> page.</p>
</div>
<ol>
<li>
<p>Get a USB containing a Windows image. Ventoy, Rufus, and the official Windows media creation tool are the only methods that can be used to create a Windows USB.</p>
</li>
<li>
<p>Bypassing Windows 11 installation checks:</p>
<ol>
<li>At the Windows installer, select &quot;Repair my Computer&quot;.</li>
<li>Select &quot;Command prompt&quot;. (May be hidden under an advanced section)</li>
<li>Type in <code v-pre>regedit</code> and press Enter</li>
<li>Navigate to <code v-pre>HKEY_LOCAL_MACHINE\SYSTEM\Setup</code></li>
<li>Right click <code v-pre>Setup</code> and create a new <code v-pre>Key</code> called <code v-pre>LabConfig</code></li>
<li>Right click <code v-pre>LabConfig</code> and create new <code v-pre>DWORD</code> (32-bit) called <code v-pre>BypassSecureBootCheck</code> and set its value to 1</li>
<li>Right click <code v-pre>LabConfig</code> and create new <code v-pre>DWORD</code> (32-bit) called <code v-pre>BypassTPMCheck</code> and set its value to 1</li>
<li>Close Registry Editor.</li>
<li>Run <code v-pre>setup</code> in the command prompt.</li>
<li>Install Windows as you would normally.</li>
</ol>
</li>
<li>
<p>Install drivers:</p>
</li>
</ol>
<ul>
<li>See <a href="https://coolstar.org/chromebook/windows-install.html" target="_blank" rel="noopener noreferrer">Coolstar's Windows Install Helper</a> for drivers. Some of the drivers may be paid.</li>
</ul>
<h2 id="installing-cab-drivers" tabindex="-1"><a class="header-anchor" href="#installing-cab-drivers"><span>Installing <code v-pre>.cab</code> drivers</span></a></h2>
<p>Cab drivers are a little weird, but they're easy to install.</p>
<ol>
<li>Create a new folder.</li>
<li>Open your cab driver using Windows Explorer. 7Zip will not work.</li>
<li>Copy all the files to that empty folder.</li>
<li>Enable the option to show file extensions.</li>
<li>Right click on all the <code v-pre>.inf</code> files, and select install.</li>
<li>Reboot.</li>
</ol>
<h2 id="paid-drivers" tabindex="-1"><a class="header-anchor" href="#paid-drivers"><span>Paid Drivers</span></a></h2>
<p>Please see <a href="https://coolstar.org/chromebook/driverlicense/login.html" target="_blank" rel="noopener noreferrer">CoolStar's driver portal</a> for a step by step tutorial on purchasing drivers.</p>
<h3 id="what-drivers-do-i-need-to-buy" tabindex="-1"><a class="header-anchor" href="#what-drivers-do-i-need-to-buy"><span>What drivers do I need to buy?</span></a></h3>
<table>
<thead>
<tr>
<th>CPU Generation</th>
<th>Paid Drivers</th>
<th>Notes</th>
</tr>
</thead>
<tbody>
<tr>
<td><strong>Sandybridge</strong></td>
<td>n/a</td>
<td></td>
</tr>
<tr>
<td><strong>Ivybridge</strong></td>
<td>n/a</td>
<td></td>
</tr>
<tr>
<td><strong>Haswell</strong></td>
<td>n/a</td>
<td></td>
</tr>
<tr>
<td><strong>Broadwell</strong></td>
<td>n/a</td>
<td></td>
</tr>
<tr>
<td><strong>Baytrail</strong></td>
<td>n/a</td>
<td></td>
</tr>
<tr>
<td><strong>Braswell</strong></td>
<td>n/a</td>
<td></td>
</tr>
<tr>
<td><strong>Skylake</strong></td>
<td>AVS</td>
<td>Models with a Celeron, Pentium, i3 or m3 CPU are eligible for a AUE discount.</td>
</tr>
<tr>
<td><strong>Apollo Lake</strong></td>
<td>AVS or SOF</td>
<td>Can use either SOF or AVS</td>
</tr>
<tr>
<td><strong>Kaby Lake</strong></td>
<td>AVS</td>
<td></td>
</tr>
<tr>
<td><strong>Amber Lake</strong></td>
<td>AVS</td>
<td></td>
</tr>
<tr>
<td><strong>Gemini Lake</strong></td>
<td>SOF</td>
<td></td>
</tr>
<tr>
<td><strong>Comet Lake</strong></td>
<td>SOF</td>
<td></td>
</tr>
<tr>
<td><strong>Picasso / Dali</strong></td>
<td>n/a</td>
<td></td>
</tr>
<tr>
<td><strong>Tiger Lake</strong></td>
<td>SOF, TB4</td>
<td>TB4 and SOF can be purchased as a bundle.</td>
</tr>
<tr>
<td><strong>Jasper Lake</strong></td>
<td>SOF</td>
<td></td>
</tr>
<tr>
<td><strong>Alder Lake</strong></td>
<td>SOF, TB4</td>
<td>TB4 and SOF can be purchased as a bundle.</td>
</tr>
<tr>
<td><strong>Cezanne</strong></td>
<td>SOF</td>
<td></td>
</tr>
<tr>
<td><strong>Mendocino</strong></td>
<td>SOF</td>
<td></td>
</tr>
</tbody>
</table>
</div></template>


